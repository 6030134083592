<script>
import { defineComponent } from "vue";
import { wagonOrderComputed, wagonOrdersMethods } from "@/state/helpers";
import successAnimation from "@/components/widgets/lupuorrc.json";
import errorAnimation from "@/components/widgets/spxnqpau.json";
import waitingAnimation from "@/components/widgets/xhebrhsj.json";
import Lottie from "@/components/widgets/lottie.vue";

export default defineComponent({
  name: "Step5",
  components: {
    lottie: Lottie,
  },
  data() {
    return {
      successAnimation: { animationData: successAnimation },
      errorAnimation: { animationData: errorAnimation },
      waitingAnimation: { animationData: waitingAnimation },
    };
  },
  methods: {
    ...wagonOrdersMethods,
    closeAnyExistingModal() {
      const modals = document.querySelectorAll(".modal");
      modals.forEach((modal) => {
        document.querySelector(`#${modal.id} .btn-close`).click();
      });
    },
    downloadFile(path) {
      let url = process.env.VUE_APP_ORDER_URL + path;
      fetch(url)
        .then((resp) => resp.blob())
        .then((blobobject) => {
          const blob = window.URL.createObjectURL(blobobject);
          const anchor = document.createElement("a");
          anchor.style.display = "none";
          anchor.href = blob;
          anchor.download = url.toString().split("/")[
            url.toString().split("/").length - 1
          ];
          document.body.appendChild(anchor);
          anchor.click();
          window.URL.revokeObjectURL(blob);
        })
        .catch(() => alert("An error in downloading the file sorry"));
    },
    closeAnyModal() {
      const modals = document.querySelectorAll(".modal");
      modals.forEach((modal) => {
        try {
          document.querySelector(`#${modal.id} .btn-close`).click()
        } catch {
          console.log(`Failed to close modal with id: ${modal.id}`);
        }
      });
    },
  },
  computed: {
    ...wagonOrderComputed,
    request_status: {
      get() {
        return this.$store
          ? this.$store.state.wagonOrder.request_status
          : "initial";
      },
      set(value) {
        this.setRequestStatus(value);
      },
    },
    order_number() {
      return localStorage.getItem("wagon_order");
    },
    pre_order: {
      get() {
        return this.$store ? this.$store.state.wagonOrder.pre_order : {};
      },
      set(value) {
        this.setPreOrder(value);
      },
    },
  },
});
</script>

<template>
  <template v-if="request_status === 'success'">
    <div class="pt-5 pb-3">
      <lottie
        colors="success:#405189,secondary:#02a8b5"
        :options="successAnimation"
        :height="140"
        :width="140"
        class="mb-5"
      />
      <div
        class="d-flex justify-content-center align-items-start gap-5 text-center"
      >
        <div>
          <h2 class="">Order Number</h2>
          <h1 class="mb-0">
            <router-link
              @click="closeAnyModal()"
              :to="{
                name: 'orders_wagon_detail',
                params: { id: order_number },
              }"
            >
              <span class="badge badge-gradient-info">
                {{ order_number }}
              </span>
            </router-link>
          </h1>
        </div>
        <div
          v-if="
            pre_order &&
            pre_order.type === 'code_order' &&
            pre_order.applications
          "
        >
          <h2 class="text-center">Applications</h2>
          <div class="d-flex justify-content-center gap-3">
            <h1
              class="mb-0"
              v-for="application in pre_order.applications"
              :key="application"
              @click="downloadFile(application.file)"
            >
              <span class="badge badge-gradient-secondary cursor-pointer">
                {{ application.number }}
                <i class="mdi mdi-download"></i>
              </span>
            </h1>
          </div>
        </div>
      </div>
    </div>
  </template>

  <template v-else-if="request_status === 'creating'">
    <div class="py-5">
      <lottie
        colors="primary:#405189,secondary:#02a8b5"
        :options="waitingAnimation"
        :height="140"
        :width="140"
        class="mb-5"
      />
      <div class="text-center">
        <h2 class="my-0">We are creating your order...</h2>
      </div>
    </div>
  </template>

  <template v-else-if="request_status === 'error'">
    <div class="py-5 pb-4">
      <lottie
        colors="danger:#405189,secondary:#02a8b5"
        :options="errorAnimation"
        :height="140"
        :width="140"
        class="mb-5"
      />
      <div class="text-center">
        <h2 class="my-4">Something went wrong</h2>
        <b-button variant="success" @click="request_status = 'initial'">
          Try again
        </b-button>
      </div>
    </div>
  </template>
</template>

<style scoped></style>
