<script>
import {defineComponent} from 'vue'
import {wagonOrdersMethods, wagonOrderComputed} from "@/state/helpers"
import ValidationError from "@/views/pages/orders/container/ValidationError.vue";
import SelectStations from "@/components/custom/SelectStations.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import Swal from "sweetalert2";

export default defineComponent({
  name: "Step1",
  emits: ['go-next-step'],
  components: {SelectStations, ValidationError, Multiselect},
  props: {
    perform: {
      type: String,
      required: false,
      default: 'create'
    }
  },
  data() {
    return {
      multiSelect: {
        positions: {
          options: [
            {value: 'rail_forwarder', label: 'Rail forwarder'},
            {value: 'block_train', label: 'Block train'},
            {value: 'multi_modal', label: 'Multi modal'},
          ],
          placeholder: 'Select position',
        },
        order_types: {
          options: [
            {value: 'import', label: 'Import'},
            {value: 'export', label: 'Export'},
            {value: 'transit', label: 'Transit'},
          ],
          placeholder: 'Select order type',
        },
        shipment_statuses: {
          options: [
            {value: 'in_process', label: 'In Process'},
            {value: 'delivered', label: 'Delivered'},
            {value: 'completed', label: 'Completed'},
          ],
          placeholder: 'Select shipment status',
        },
        payment_statuses: {
          options: [
            {value: 'reserved', label: 'Reserved'},
            {value: 'issued', label: 'Issued'},
            {value: 'received', label: 'Received'},
          ],
          placeholder: 'Select payment status',
        },
      },
      step1: {
        lot_number: '',
        position: '',
        shipper: '',
        consignee: '',
        order_type: '',
        shipment_status: '',
        payment_status: '',
        date: '',
        departure_id: null,
        destination_id: null,
      },
      step1_is_submitted: false,
    }
  },
  computed: {
    ...wagonOrderComputed,
    autocomplete: {
      get() {
        return this.$store ? this.$store.state.wagonOrder.autocomplete : ''
      },
      set(value) {
        this.setAutoComplete(value)
      }
    },

    lot_number: {
      get() {
        return this.$store ? this.$store.state.wagonOrder.lot_number : ''
      },
      set(value) {
        this.setLotNumber(value)
      }
    },
    position: {
      get() {
        return this.$store ? this.$store.state.wagonOrder.position : ''
      },
      set(value) {
        this.setPosition(value)
      }
    },
    shipper: {
      get() {
        return this.$store ? this.$store.state.wagonOrder.shipper : ''
      },
      set(value) {
        this.setShipper(value)
      }
    },
    consignee: {
      get() {
        return this.$store ? this.$store.state.wagonOrder.consignee : ''
      },
      set(value) {
        this.setConsignee(value)
      }
    },
    order_type: {
      get() {
        return this.$store ? this.$store.state.wagonOrder.order_type : ''
      },
      set(value) {
        this.setOrderType(value)
      }
    },
    shipment_status: {
      get() {
        return this.$store ? this.$store.state.wagonOrder.shipment_status : ''
      },
      set(value) {
        this.setShipmentStatus(value)
      }
    },
    payment_status: {
      get() {
        return this.$store ? this.$store.state.wagonOrder.payment_status : ''
      },
      set(value) {
        this.setPaymentStatus(value)
      }
    },
    date: {
      get() {
        return this.$store ? this.$store.state.wagonOrder.date : ''
      },
      set(value) {
        this.setDate(value)
      }
    },
    departure_id: {
      get() {
        return this.$store ? this.$store.state.wagonOrder.departure_id : ''
      },
      set(value) {
        this.setDepartureId(value)
      }
    },
    destination_id: {
      get() {
        return this.$store ? this.$store.state.wagonOrder.destination_id : ''
      },
      set(value) {
        this.setDestinationId(value)
      }
    },

    is_pre_order: {
      get() {
        return this.$store
            ? this.$store.state.wagonOrder.pre_order && this.$store.state.wagonOrder.pre_order.type === 'code_order'
            : false
      },
      set(value) {
        this.setIsPreOrder(value)
      }
    },
  },
  methods: {
    ...wagonOrdersMethods,
    onStationSelected(station) {
      if (station.option === 'departure') {
        this.departure_id = station.value ? station.value.value : null
      } else if (station.option === 'destination') {
        this.destination_id = station.value ? station.value.value : null
      }
    },
    validate() {
      this.step1_is_submitted = true
      return this.lot_number && this.position && this.order_type && this.shipment_status && this.payment_status && this.date && this.departure_id && this.destination_id
    },
    goNextStep() {
      if (this.validate()) {
        this.$emit('go-next-step')
        return true
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        Toast.fire({
          icon: 'warning',
          title: 'Please fill all fields',
        })
        return false
      }
    },
    clearOrder() {
      this.fullClean()
    }
  },
})
</script>

<template>

  <div class="d-flex justify-content-between ">
    <div>
      <h5 class="mb-1">Order Information (1)</h5>
      <p class="text-muted mb-4">
        Please fill all information below
      </p>
    </div>
    <div>
      <VTooltip v-if="perform === 'create' && !is_pre_order">
        <i
            @click="clearOrder()"
            class="mdi mdi-refresh cursor-pointer fs-1"></i>
        <template v-slot:popper>
          Click to clear fields
        </template>
      </VTooltip>
    </div>
  </div>

  <form @submit.prevent="goNextStep()">
    <b-row gutterY="3" class="mb-3">
      <b-col lg="6">
        <label class="form-label">
          Lot Number
          <span class="text-danger">*</span>
        </label>
        <input v-model="lot_number" class="form-control"
               placeholder="Required example textarea" required/>
        <ValidationError :show="step1_is_submitted" :value="lot_number"/>
      </b-col>

      <b-col lg="6">
        <label class="form-label">
          Position
          <span class="text-danger">*</span>
        </label>
        <Multiselect v-model="position" v-bind="multiSelect.positions"/>
        <ValidationError :show="step1_is_submitted" :value="position"/>
      </b-col>
    </b-row>

    <b-row gutterY="3" class="mb-3">
      <b-col lg="4">
        <label class="form-label">
          Shipper
          <span class="text-danger">*</span>
        </label>
        <input v-if="!is_pre_order" v-model="shipper" type="text" class="form-control"
               placeholder="Enter shipper name"/>
        <input v-else-if="is_pre_order" disabled
               :value="shipper" type="text" class="form-control" placeholder="Enter shipper name"/>
      </b-col>

      <b-col lg="4">
        <label class="form-label">
          Consignee
          <span class="text-danger">*</span>
        </label>
        <input v-if="!is_pre_order" v-model="consignee" type="text" class="form-control"
               placeholder="Enter consignee name"/>
        <input v-else-if="is_pre_order" disabled
               :value="consignee" type="text" class="form-control" placeholder="Enter consignee name"/>
      </b-col>
      <b-col lg="4">
        <label class="form-label">
          Order Type
          <span class="text-danger">*</span>
        </label>
        <div style="z-index: 999">
          <Multiselect v-model="order_type" v-bind="multiSelect.order_types"/>
        </div>
        <ValidationError :show="step1_is_submitted" :value="order_type"/>
      </b-col>
    </b-row>

    <b-row gutterY="3" class="mb-3">
      <b-col lg="4">
        <label class="form-label">
          Shipment Status
          <span class="text-danger">*</span>
        </label>
        <Multiselect v-model="shipment_status" v-bind="multiSelect.shipment_statuses"/>
        <ValidationError :show="step1_is_submitted" :value="shipment_status"/>
      </b-col>

      <b-col lg="4">
        <label class="form-label">
          Payment Status
          <span class="text-danger">*</span>
        </label>
        <Multiselect
            v-model="payment_status" v-bind="multiSelect.payment_statuses"/>
        <ValidationError :show="step1_is_submitted" :value="payment_status"/>
      </b-col>
      <b-col lg="4">
        <label class="form-label">
          Date
          <span class="text-danger">*</span>
        </label>
        <VueDatePicker v-model="date" :disabled="is_pre_order"
                       auto-apply partial-flow placeholder="Select date" class="border-0"
                       style="transform: scaleY(0.95) translateY(-1px); z-index: 1"/>
        <ValidationError :show="step1_is_submitted" :value="date"/>
      </b-col>
    </b-row>

    <div class="row gy-3">
      <template v-if="autocomplete.departures">
        <SelectStations :current_departure="autocomplete.departures"
                        :current_destination="autocomplete.destinations"
                        @onSelect="onStationSelected" :ratio="[6,6,6,6]"
                        :disabled_departure="is_pre_order"
                        :disabled_destination="is_pre_order"
        />
      </template>
      <template v-else>
        <SelectStations @onSelect="onStationSelected" :ratio="[6,6,6,6]"/>
      </template>
      <ValidationError :show="step1_is_submitted"
                       :value="departure_id">
        <template v-slot:message>Departure field is required !</template>
      </ValidationError>
      <ValidationError :show="step1_is_submitted"
                       :value="destination_id">
        <template v-slot:message>Destination field is required !</template>
      </ValidationError>
    </div>

    <div class="d-flex align-items-start gap-3 mt-3">
      <b-button type="submit" variant="success" class="btn-label right ms-auto">
        <i class="ri-home-2-line
                            label-icon
                            align-middle
                            fs-16
                            ms-2
                          "></i>Go to Order Info (2)
      </b-button>
    </div>

  </form>
</template>

<style scoped>

</style>