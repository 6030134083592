<script>
import {defineComponent} from "vue";
import {wagonOrderComputed, wagonOrdersMethods} from "@/state/helpers";
import axios from "axios";
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
  name: "Step4",
  emits: ["created", "goNextStep", "goPreviousStep"],
  data() {
    return {
      containerTypes: [
        {id: "20", name: "20"},
        {id: "20HC", name: "20HC"},
        {id: "40", name: "40"},
        {id: "40HC", name: "40HC"},
        {id: "45", name: "45"},
      ],
      taskTypes: [],
      task_type_id: null,
      task_deadline: null,
      create_started: false,
    };
  },
  components: {
    Multiselect
  },
  computed: {
    ...wagonOrderComputed,
    autocomplete_order_number: {
      get() {
        return this.$store
            ? this.$store.state.wagonOrder.autocomplete.order_number
                ? this.$store.state.wagonOrder.autocomplete.order_number
                : null
            : null;
      },
      set(value) {
        this.$store.state.wagonOrder.autocomplete.order_number = value;
      },
    },
    container_types: {
      get() {
        return this.$store ? this.$store.state.wagonOrder.container_types : "";
      },
    },
    counterparties: {
      get() {
        return this.$store ? this.$store.state.wagonOrder.counterparties : "";
      },
    },
    request_status: {
      get() {
        return this.$store
            ? this.$store.state.wagonOrder.request_status
            : "initial";
      },
      set(value) {
        this.setRequestStatus(value);
      },
    },
    quantity: {
      get() {
        return this.$store ? this.$store.state.wagonOrder.quantity : "";
      },
      set(value) {
        this.setQuantity(value);
      },
    },
    agreed_rate_per_wagon: {
      get() {
        return this.$store
            ? this.$store.state.wagonOrder.agreed_rate_per_wagon
            : "";
      },
      set(value) {
        this.setAgreedRatePerWagon(value);
      },
    },
    agreed_rate_per_tonne: {
      get() {
        return this.$store
            ? this.$store.state.wagonOrder.agreed_rate_per_tonne
            : "";
      },
      set(value) {
        this.setAgreedRatePerTonne(value);
      },
    },
    weight: {
      get() {
        return this.$store ? this.$store.state.wagonOrder.weight : "";
      },
      set(value) {
        this.setWeight(value);
      },
    },
    pre_order: {
      get() {
        return this.$store ? this.$store.state.wagonOrder.pre_order : {};
      },
      set(value) {
        this.setPreOrder(value);
      },
    },

    formIsInvalid() {
      let hasCounterparties = this.counterparties.length > 0;
      let hasUnfilledPriceInputs =
          this.quantity === "" ||
          this.weight === "" ||
          this.agreed_rate_per_wagon === "" ||
          this.agreed_rate_per_tonne === "" ||
          this.counterparties.some((counterparty) => {
            return counterparty.categories.some((category) => {
              return category.price === "";
            });
          });

      return hasCounterparties && hasUnfilledPriceInputs;
    },

    totalProfit() {
      let quantity = this.quantity;
      let weight = this.weight;
      let agreed_rate_per_tonne = this.agreed_rate_per_tonne;
      let agreed_rate_per_wagon = this.agreed_rate_per_wagon;

      let counterparty_prices = 0;

      this.counterparties.forEach((counterparty) => {
        counterparty.categories.forEach((category) => {
          counterparty_prices += parseFloat(category.price);
        });
      });

      let total =
          quantity * agreed_rate_per_wagon +
          weight * agreed_rate_per_tonne -
          quantity * counterparty_prices;

      if (isNaN(total)) {
        return 0;
      }

      return total.toFixed(1);
    },
  },
  methods: {
    ...wagonOrdersMethods,
    async createOrder() {
      let counterparties = [];
      let applications = [];
      let pre_costs = [];
      let end_point_url = "";

      this.$store.state.wagonOrder.counterparties.forEach((counterparty) => {
        const categories = counterparty.categories;
        const territories = counterparty.territories
            ? counterparty.territories
            : [];
        categories.forEach((category) => {
          counterparties.push({
            category_id: category.value,
            counterparty_id: counterparty.counterparty.value,
          });
          pre_costs.push({
            category_id: category.value,
            counterparty_id: counterparty.counterparty.value,
            preliminary_cost: category.price,
          });
        });
        if (this.pre_order && this.pre_order.type === "code_order") {
          applications.push({
            territories: territories.map((t) => {
              return {
                id: t.value,
                name: t.label,
              };
            }),
            forwarder_id: counterparty.counterparty.value,
          });
        }
      });

      const date = new Date(this.$store.state.wagonOrder.date);

      let order = {
        order: {
          lot_number: this.$store.state.wagonOrder.lot_number,
          date: date.toISOString().split("T")[0],
          position: this.$store.state.wagonOrder.position,
          type: this.$store.state.wagonOrder.order_type,
          shipment_status: this.$store.state.wagonOrder.shipment_status,
          payment_status: this.$store.state.wagonOrder.payment_status,
          shipper: this.$store.state.wagonOrder.shipper,
          consignee: this.$store.state.wagonOrder.consignee,
          departure_id: this.$store.state.wagonOrder.departure_id,
          destination_id: this.$store.state.wagonOrder.destination_id,
          border_crossing: this.$store.state.wagonOrder.border_crossing,
          conditions_of_carriage:
          this.$store.state.wagonOrder.conditions_of_carriage,
          rolling_stock: this.$store.state.wagonOrder.rolling_stock,
          departure_country: this.$store.state.wagonOrder.departure,
          destination_country: this.$store.state.wagonOrder.destination,
          comment: this.$store.state.wagonOrder.comment,
          company_id: this.$store.state.wagonOrder.company_id,
          counterparties: counterparties,
        },
        product_id: this.$store.state.wagonOrder.product_id,
        is_pre_order: this.$store.state.wagonOrder.is_pre_order,
        additional_cost: this.$store.state.wagonOrder.agreed_rate_per_wagon,
        agreed_rate_per_tonn:
        this.$store.state.wagonOrder.agreed_rate_per_tonne,
        quantity: this.$store.state.wagonOrder.quantity,
        weight: this.$store.state.wagonOrder.weight,
        wagon_preliminary_costs: pre_costs,
        process_header_id: this.task_type_id,
        due_date: this.task_deadline,
        inquiry_id: this.$route.query.inquiry_id,
      };
      end_point_url = "/wagon_order/create/";
      if (this.pre_order && this.pre_order.type === "code_order") {
        order.pre_order_id = this.pre_order.id;
        order.applications = applications;
        end_point_url = "/pre_order/convert_pre_order/wagon_order/";
      }

      try {
        this.request_status = "creating";
        let response = await axios.post(end_point_url, order);
        if (this.pre_order.type === "code_order") {
          this.pre_order.applications = response.data.applications
              ? response.data.applications
              : [];
        }
        localStorage.setItem("wagon_order", response.data.order);
        if (localStorage.getItem("wagon_autocompletes")) {
          let exists = JSON.parse(
              localStorage.getItem("wagon_autocompletes")
          ).find((item) => item === this.autocomplete_order_number);

          if (!exists) {
            localStorage.setItem(
                "wagon_autocompletes",
                JSON.stringify([
                  this.autocomplete_order_number,
                  ...JSON.parse(localStorage.getItem("wagon_autocompletes")),
                ])
            );
          }
        } else {
          localStorage.setItem(
              "wagon_autocompletes",
              JSON.stringify([this.autocomplete_order_number])
          );
        }
        this.request_status = "success";
        this.$emit("created");
      } catch {
        this.request_status = "error";
      }
    },
    async getTaskProductList() {
      try {
        let response = await axios.get('/task/product/list/')
        this.taskTypes = response.data.map(item => {
          return {
            value: item.id,
            label: item.title
          }
        })
      } catch {
        alert("error")
        this.taskTypes = []
      }
    }
  },
  mounted() {
    this.getTaskProductList()
  }
});
</script>

<template>
  <div class="d-flex flex-lg-row justify-content-between">
    <div>
      <h5>Counterparty Prices</h5>
      <p class="text-muted">Please fill all information below</p>
    </div>
    <div class="text-end">
      <h5>Total Profit</h5>
      <h3>${{ totalProfit }}</h3>
    </div>
  </div>

  <div class="border border-secondary rounded-3 p-3 overflow-hidden mt-2 mb-4">
    <b-row class="mb-0" gutter-y="4">
      <b-col sm="12">
        <div class="d-flex justify-content-between gap-4">
          <div class="w-100">
            <label class="form-label">
              Quantity
              <span class="text-danger">*</span>
            </label>
            <input
                v-if="pre_order && pre_order.type === ''"
                v-model="quantity"
                type="number"
                class="form-control"
                placeholder="Quantity"
            />
            <input
                v-else-if="pre_order && pre_order.type === 'code_order'"
                :value="quantity"
                readonly
                type="number"
                class="form-control"
                placeholder="Quantity"
            />
          </div>
          <div class="w-100">
            <label class="form-label">
              Weight
              <span class="text-danger">*</span>
            </label>
            <input
                v-if="pre_order && pre_order.type === ''"
                v-model="weight"
                type="number"
                class="form-control"
                placeholder="Weight"
            />
            <input
                v-else-if="pre_order && pre_order.type === 'code_order'"
                :value="weight"
                readonly
                type="number"
                class="form-control"
                placeholder="Weight"
            />
          </div>
          <div class="w-100">
            <label class="form-label">
              Agreed Rate Per Tonne
              <span class="text-danger">*</span>
            </label>
            <input
                v-model="agreed_rate_per_tonne"
                type="number"
                class="form-control"
                placeholder="Agreed rate per tonne"
            />
          </div>
          <div class="w-100">
            <label class="form-label">
              Agreed Rate Per Wagon
              <span class="text-danger">*</span>
            </label>
            <input
                v-model="agreed_rate_per_wagon"
                type="number"
                class="form-control"
                placeholder="Agreed rate per wagon"
            />
          </div>
        </div>
      </b-col>

      <b-col
          lg="4"
          v-for="counterparty in counterparties.filter(
          (c) => c.counterparty !== null
        )"
          :key="counterparty"
      >
        <div class="border border-dashed border-secondary rounded-3 p-3">
          <h5 class="mb-4">{{ counterparty.counterparty.label }}</h5>
          <b-row gutter-y="3">
            <b-col
                sm="12"
                v-for="category in counterparty.categories"
                :key="category"
            >
              <label class="form-label">
                {{ category.label }}
                <span class="text-danger">*</span>
              </label>
              <input
                  v-model="category.price"
                  type="number"
                  class="form-control"
                  :placeholder="`Price for ${category.label}`"
              />
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>

  <b-row class="align-items-center justify-content-between">
    <b-col lg="6">
      <label class="form-label">Select template task <span class="text-muted">(optional)</span> </label>
      <Multiselect v-model="task_type_id" :options="taskTypes"/>
    </b-col>
    <b-col lg="6">
      <label class="form-label">Deadline </label>
      <input v-model="task_deadline" type="date" class="form-control">
    </b-col>
  </b-row>

  <div class="text-end mt-4">
    <b-button
        v-if="request_status === 'initial'"
        @click="createOrder()"
        :disabled="formIsInvalid"
        variant="success"
        class="fs-18 w-100"
    >Create New Order
    </b-button>
    <button
        v-else
        type="button"
        disabled
        class="btn btn-success btn-load fs-18 w-100"
    >
      Creating New Order...
      <b-spinner class="ms-2"></b-spinner>
    </button>
  </div>
</template>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
