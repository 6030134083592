import axios from "axios"

class OrdersApi {
    constructor() {
        this.base_url = process.env.VUE_APP_ORDER_URL;
    }

    async getCounterpartyList() {
        let data = {
            results: []
        }
        try {
            let response = await axios.get(`/counterparty/counterparties/`)
            data = await response.data
        } catch (error) {
            console.log(error)
        }
        return data
    }

    async getCategoryList() {
        let data = {
            results: []
        }
        try {
            let response = await axios.get(`/counterparty/categories/`)
            data = await response.data
        } catch (error) {
            console.log(error)
        }
        return data
    }

    async getContainerOrders() {
        let data = []
        try {
            let response = await fetch(`${this.base_url}/container_order/list/`)
            data = await response.json()
        } catch (error) {
            console.log(error)
        }
        return data
    }

    async getWagonOrders() {
        let data = []
        try {
            let response = await fetch(`${this.base_url}/wagon_order/list/`)
            data = await response.json()
        } catch (error) {
            console.log(error)
        }
        return data
    }

    async getEmptyWagonOrders() {
        let data = []
        try {
            let response = await fetch(`${this.base_url}/wagon_empty_order/list/`)
            data = await response.json()
        } catch (error) {
            console.log(error)
        }
        return data
    }

    async createWagonOrder(data) {
        let response = await axios.post(`${this.base_url}/wagon_order/create/`, data)
        return response
    }

    async createEmptyWagonOrder(data) {
        let response = await axios.post(`/wagon_empty_order/create/`, data)
        return response
    }

}

export default OrdersApi;