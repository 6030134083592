<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "ValidationError",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    value: {
      type: String || null,
      required: true
    }
  }
})
</script>

<template>
  <div class="mt-2" v-if="show && !value">
    <h6 class="my-0 text-danger">
      <slot name="message">This field is required !</slot>
    </h6>
  </div>
</template>

<style scoped>

</style>